//Colors manual

// Highlight Colors
//
// Colors we use for higlighting states.
//
// $life-green - #3DCD58, primary green
// $logo-green - #009530, secondary green
// $dark-gray - #626469, base gray
//
// Styleguide Base.colors

//Primary Colors
$orange-crazy: #F79829;
$green-atomic: #8CC63F;
$grafite:   #707070;
$moon-gray:   #86868D;
$snow-white:    #F1F1F1;
$ash-gray: #E8E8E8;

//Secondary Colors
$red-blood:     #D9000D;
$yellow-mother:   #ffd200;
$blue-shinny: #0090DF;

//Informational Colors
$black: #000;
$white: #fff;

//Functional colors
$main-color: $blue-shinny;
$primary-color: $red-blood;
$secondary-color: $green-atomic;
$base-light: $white;
$base-dark: $black;
$background-color: $ash-gray;
$main-light: #979ca3;

//Conditional colors
$color-danger: $red-blood;
$color-success: $green-atomic;
$color-warning: $yellow-mother;

//text-colors
$text-color: lighten($base-dark, 10%);
$text-light: $moon-gray;
$text-cta: $main-color;
$text-normal: $grafite;

//Fonts
$font-default: 'Poppins', 'Calibri', sans-serif;

$border-radius-default: 4px;
$transition-default: all .35s ease-in-out;

//Size variables
$screen-mobile: 576px;
$screen-tablet: 768px;
$screen-tablet-landscape: 992px;
$screen-desktop: 1200px;

//Structure sizes
$header-height: 90px;

//fonts breakes
$min_width: 320px;
$max_width: 1200px;
$min_font: 14px;
$max_font: 18px;

$gradient-main: #173a81, #ce1b49;

//Page Depth
$z-default: 1;
$z-second: 5;
$z-third: 10;