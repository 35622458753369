.portfolio {
    //pages
    &--internal {
        color: $text-normal;

        &,
        .portfolio__body {
            overflow: initial;
            //overflow-x: hidden;
        }
        .page-content {
            width: 100%;
            opacity: 1;
            transform: initial;
            padding: 0;

            .page-back {
                .fas {
                    top: 0;
                    left: 0;
                    opacity: 1;
                    transform: scale(1) rotate(0) translate(0);
                }
            }
            .title {
                position: relative;
                z-index: 1;
                font-size: 2rem;
                text-transform: none;
                margin-bottom: .25em;

                @media (min-width: $screen-tablet) {
                    font-size: 4rem;
                }

                b {
                    font-size: 1em;
                }

                &--secondary {
                    font-size: 2em;
                }
            }
            .description {
                font-size: 1.1rem;
                margin-bottom: 2em;
            }
            &__header {
                position: relative;
                padding: 2em;
                padding-top: 21vh;
                min-height: 100vh;

                @media (min-width: $screen-tablet) {
                    z-index: 10;
                    width: calc(100% - 40vh);
                    min-height: initial;
                    padding: 2em;
                }
            }
            &__image {
                img {
                    max-width: 50vw;

                    @media (min-width: $screen-tablet) {
                        max-width: 100%;
                    }
                }
            }
            &__body {
                padding: 2em;
            }
            &__section {
                box-sizing: border-box;
                
                &--first {
                    min-height: 100vh;
                }
                &--details {
                    padding: 0;
                    background-color: $base-light;

                    .page-content__body {
                        padding: 0;

                        @media (min-width: $screen-tablet) { 
                            display: flex;
                            justify-content: flex-start;
                        }
                        &__image,
                        &__about {
                            width: 100%;

                            @media (min-width: $screen-tablet) {  
                                width: 50%;
                            }
                        }
                        &__image {
                            &--bg {
                                min-height: 50vh;
                            }
                        }
                        &__about {
                            padding: 4em 2em;

                            .title {
                                font-size: 2em;
                                margin-bottom: .75em;
                            }
                            p {
                                opacity: .75;
                            }
                        }
                    }
                }
                &--image {
                    background-color: transparent;
                    
                    .page-content__body {
                        padding: 0;
                    }
                }
            }
            &--ui {
                .title {
                    color: $text-normal;
                }
                .btn-outline-primary {
                    color: $main-color;
                    box-shadow: 0 0 0 0 rgba($main-color, .5);

                    &,
                    &:hover,
                    &:focus,
                    &:active,
                    &:active:focus {
                        box-shadow: 0 0 0 0 rgba($main-color, .5);
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: $base-light;
                        background-color: $main-color;
                        border-color: $main-color;
                    }
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba($main-color, .5);
                    }
                }
                .box-caller {
                    &:before {
                        background-color: $main-color;
                    }
                    &:hover,
                    &:focus {
                        &:before {
                            background-color: darken($main-color, 5%);
                            transition: $transition-default;
                        }
                    }
                }
            }
            &--ux {
                .title {
                    color: $text-normal;
                }
                .btn-outline-primary {
                    color: $primary-color;
                    border-color: $primary-color;
                    box-shadow: 0 0 0 0 rgba($primary-color, .5);

                    &,
                    &:hover,
                    &:focus,
                    &:active,
                    &:active:focus {
                        box-shadow: 0 0 0 0 rgba($primary-color, .5);
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: $base-light;
                        background-color: $primary-color;
                        border-color: $primary-color;
                    }
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba($primary-color, .5);
                    }
                }
                .box-caller {
                    &:before {
                        background-color: $primary-color;
                    }
                    &:hover,
                    &:focus {
                        &:before {
                            background-color: darken($primary-color, 5%);
                            transition: $transition-default;
                        }
                    }
                }
            }
        }
        .box-caller {
            position: fixed;
            right: 0;
            top: 0;
            z-index: 11;
            min-height: 21vh;
            padding-top: 5vh;
            padding-right: 2rem;

            @media (min-width: $screen-tablet) {  
                z-index: 0;
            }

            a {
                position: relative;
                z-index: 1;
                transition: $transition-default;

            }
            .title {
                font-size: 2rem;
                text-transform: uppercase;
                color: $text-normal;
                
                &,
                &:hover,
                &:focus {
                    color: $base-light;
                    text-decoration: none;
                }
            }
            i {
                display: block;
                font-style: normal;
            }
            .go-to-other {
                width: .5em;
                height: .5em;
                background-color: $base-light;
            }
            &:before {
                content: "";
                position: absolute;
                top: -90vh;
                right: -15vh;
                z-index: 0;
                display: block;
                width: 100vh;
                height: 100vh;
                opacity: .95;
                background-color: $text-normal;
                transform: rotate(15deg);
                transition: $transition-default;
                cursor: pointer;
            }
            &:hover,
            &:focus {
                &:before {
                    background-color: darken($text-normal, 5%);
                    transition: $transition-default;
                }
            }
        }
    }
}