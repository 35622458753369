

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  font-family: $font-default;
}

.landing {
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem;
  background-color: $main-color;
  color: $base-light;
  @include gradient(270deg, $gradient-main);
  background-size: 400% 400%;
  animation: backgroundAnimated 30s ease infinite;

  @media (min-width: $screen-tablet) {
    padding: 5rem;
  }

  h1 {
    font-size: 3rem;
    font-weight: 900;
    text-transform: uppercase;
    opacity: .85;
  
    @media (min-width: $screen-tablet) {
      font-size: 5rem;
    }
  }
  p,
  ul {
    font-size: 2rem;
    font-weight: 200;
    opacity: .85;
    
    small {
      font-size: .5em;
  
      i {
        background-color: #ff0097;
        color: $base-light;
        padding: .25em;
        border-radius: .1em;
        margin-right: .25em;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    position: relative;
    display: inline-block;
    color: #ff0097;
    font-weight: 600;
    transition: all .35s ease-in-out;
  
    &:after {
      content: "";
      position: absolute;
      bottom: .1em;
      display: block;
      width: 0;
      height: .55em;
      background-color: rgba(#ff0097, .5);
      transition: all .35s ease-in-out;
    }
    i {
      transition: all .35s ease-in-out;
    }
  
    &:hover,
    &:focus {
      //font-weight: 400;
      text-decoration: none;
      color: $yellow-mother;
      transition: all .35s ease-in-out;
  
      &:after {
        width: 100%;
        transition: all .35s ease-in-out;
      }
      i {
        background: darken($main-color, 20%);
        transition: all .35s ease-in-out;
      }
    }
  }
}


@-webkit-keyframes backgroundAnimated {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes backgroundAnimated {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes backgroundAnimated { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.oculos-mask {
  position: relative;
  width: 100vh;
  height: 100vh;
  margin: 0 auto;

  .poe-image {
    width: 100%;
    height: auto;
  }
  .poe-oculos,
  .oculos-lentes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .oculos-lentes {
    clip-path: url("#mask");
    
    video {
      position: absolute;
      top: 0%;
      left: 0%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1;
      overflow: hidden;
      mask-image: url("/img/oculos-lentes.svg");
      mask-repeat: no-repeat;
      mask-size: 350px;
      mask-position: center;
    }
  }
}