.portfolio {
    &,
    &__body {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        padding: 0;
        margin: 0;
        background-color: $background-color;
    }
    .cover-buttons {
        position: relative;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
    }
    .cta-cover {
        z-index: 2;

        &--ui {
            bottom: 0;
            right: 0;

            &:before {
                bottom: -80vh;
                right: -25vh;
                transform: rotate(45deg);
                background-color: $main-color;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    bottom: -60vh;
                    right: -25vh;
                }
                @media (min-width: $screen-tablet) {
                    bottom: -60vh;
                    right: -25vh;
                }
            }
        }
    }
    &--ui,
    &--ux {
        .cover-buttons {
            z-index: 0;
        }

        .poe {
            &--red,
            &--blue,
            &--color {
                transition: all .35s ease-in-out;
            }
            &--red,
            &--blue {
                opacity: 0;
            }
        }
        .page-content {
            .page-back {    
                .fas {
                    position: relative;
                    top: 0;
                    left: 0;
                    opacity: 1;
                    transform: scale(1) rotate(0) translate(0);
                    transition: $transition-default;
                }
            }
        }
    }
    &--ui {
        .poe {
            &--color {
                right: -7rem;
                bottom: -3rem;
                width: 110vh;
                margin-right: 0;
                opacity: 1;

                img {
                    width: 100%;
                    height: auto;
                }
            }
            .oculos-lentes {
                //clip-path: url("#mask");
                position: relative;
                
                video {
                    position: absolute;
                    top: -10.3vh;
                    left: 0%;
                    min-width: 100%;
                    min-height: 100%;
                    width: 100%;
                    height: auto;
                    //z-index: -1;
                    overflow: hidden;
                    mask-image: url("/img/oculos-lentes.svg");
                    mask-repeat: no-repeat;
                    mask-size: 41.4vh;
                    mask-position: center;

                    @supports (-ms-ime-align:auto) {
                        display: none;
                    }
                }
            }
        }
        .cta-cover {
            &--ux {
                top: -100vh;
                left: 0;
                opacity: 0;
                transition: all .35s ease-in-out;

                &:before {
                    top: -60vh;
                    left: -25vh;
                    opacity: 0;
                    transform: rotate(-90deg);
                    transition: all .35s ease-in-out;
                }
            }
            &--ui {
                bottom: 100vh;
                right: 0;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    bottom: 90vh;
                }
    
                &:before {
                    bottom: -10vh;
                    right: -40vh;
                    transform: rotate(0deg);
                    transition: all .35s ease-in-out;
                }
            }
        }
        .page-content {
            &--ux {
                opacity: 0;
                transform: scale(0);
                transition: $transition-default;
            }
            &--ui {
                opacity: 1;
                transform: scale(1);
                transition: $transition-default;
            }
        }
    }
    &--ux {
        .poe {
            &--lines {
                right: -7rem;
                bottom: -10rem;
                width: 105vh;
                margin-right: 0;
                opacity: .5;
            }
        }
        .cta-cover {
            &--ui {
                height: 0;
                top: 0;
                left: 0;
                opacity: 0;
                transition: all .35s ease-in-out;

                @media (min-width: $screen-tablet) {
                    top: -100vh;
                }

                &:hover,
                &:focus {
                    opacity: 0;
                }

                &:before {
                    top: -60vh;
                    left: -25vh;
                    opacity: 0;
                    transform: rotate(-90deg);
                    transition: all .35s ease-in-out;
                }
            }
            &--ux {
                top: -8vh;
                right: 0;
                left: calc(100vw - 40vh);

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    top: -30vh;
                }
                @media (min-width: $screen-tablet) {
                    top: -30vh;
                }
    
                &:before {
                    bottom: -10vh;
                    right: -40vh;
                    transform: rotate(0deg);
                    transition: all .35s ease-in-out;
                }
                span {
                    opacity: 0;
                }
            }
        }
        .page-content {
            &--ux {
                opacity: 1;
                transform: scale(1);
                transition: $transition-default;
            }
            &--ui {
                opacity: 0;
                transform: scale(0);
                transition: $transition-default;
            }
        }
    }
}
.cta-cover {
    display: block;
    position: absolute;
    z-index: 1;
    font-weight: 700;
    font-size: 8em;
    color: $background-color;
    cursor: default !important;
    opacity: .9;
    transition: all .35s ease-in-out;

    @media (max-width: $screen-tablet) and (orientation: landscape) {
        font-size: 6.5em;
    }
    @media (min-width: $screen-tablet) {
        font-size: 13em;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border: 0;
        box-shadow: none;
        background-color: transparent;
        outline: none;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        color: $background-color;
        opacity: 1;
        transition: all .35s ease-in-out;
    }
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100vh;
        height: 100vh;
        background-color: darken($background-color, 10%);
        opacity: .9;
        transition: all .35s ease-in-out;
        cursor: pointer;
    }
    span {
        position: relative;
        display: block;
        width: 100vw;
        text-align: center;
        cursor: pointer;
        
        @media (max-width: $screen-tablet) and (orientation: landscape) {
            width: 50vw;
        }
        @media (min-width: #{$screen-tablet - 1}) {
            width: 60vh;
        }
    }
    &--ux {
        top: 0;
        left: 0;
        opacity: 1;
        transition: all .35s ease-in-out;

        &:before {
            top: -80vh;
            left: -35vh;
            transform: rotate(-45deg);
            opacity: .9;
            background-color: $primary-color;
            transition: all .35s ease-in-out;

            @media (max-width: $screen-tablet) and (orientation: landscape) {
                top: -50vh;
                left: -25vh;
            }
            @media (min-width: $screen-tablet) {
                top: -60vh;
                left: -25vh;
            }
        }
        span {
            text-align: left;
            padding-left: .45em;
            
            @media (min-width: $screen-tablet) {
                text-align: center;
                padding-left: 0;
            }
        }
    }
    &--ui {
        bottom: 0;
        right: 0;

        &:before {
            bottom: -60vh;
            right: -25vh;
            transform: rotate(45deg);
            background-color: $main-color;
            opacity: .9;
            transition: all .35s ease-in-out;
        }
        span {
            text-align: right;
            padding-right: .45em;
            
            @media (min-width: $screen-tablet) {
                text-align: center;
                padding-right: 0;
            }
        }
    }
}
.poe {
    position: absolute;
    bottom: -20vh;
    width: 90vh;
    height: auto;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: .7;
    transition: all .35s ease-in-out;

    &--red,
    &--blue,
    &--color {
        left: 0;
        right: 0;
        transition: all .35s ease-in-out;
    }
    &--red {
        left: -1em;
    }
    &--blue,
    &--color {
        left: 1em;
    }
    &--color,
    &--lines {
        bottom: -3vh;
        left: auto;
        right: calc(50vw - (90vh / 2));
        opacity: 0;
        margin-right: 0;
        margin-left: 0;
    }
    &--lines {
        opacity: 0;
        bottom: -20vh;
    }
}

.page-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-second;
    opacity: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    padding: 0 2rem;
    transform: scale(0);
    transition: $transition-default;

    @media (max-width: $screen-tablet) and (orientation: landscape) {
        //width: 50vw;
    }
    @media (min-width: $screen-tablet) {
        width: 50vw;
        padding: 2rem 4rem;
    }

    .page-back {
        position: relative;
        font-size: 4rem;
        display: inline-block;

        .fas {
            position: relative;
            top: -45vh;
            left: -45vh;
            opacity: 0;
            transform: scale(10) rotate(90deg) translate(-20vh);
            transition: $transition-default;
        }
    }
    .title {
        line-height: .9;
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: uppercase;

        @media (max-width: $screen-tablet) and (orientation: landscape) {
            width: 45vw;
            font-size: 2.5em;
        }
        @media (min-width: $screen-tablet) {
            width: auto;
            font-size: 6.5em;
        }

        b {
            font-size: 2em;
            font-weight: 700;
        }
        &--secondary {
            margin-bottom: .5em;
        }
    }
    .go-to-other {
        overflow: hidden;
        display: inline-block;
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        text-indent: -100vw;
        background-color: $base-dark;
    }
    &__header {
        display: block;
        padding-bottom: 4em;
    }
    &__list {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1rem 2rem 0;

        @media (min-width: $screen-tablet) {
            width: 50vw;
            padding: 2rem 4rem;
        }

        ul {
            font-size: 1.6em;

            @media (min-width: $screen-tablet) {
                font-size: 2em;
            }

            > li {
                margin-bottom: .25em;

                @media (min-width: $screen-tablet) {
                    margin-bottom: .5em;
                }
            }
        }
        a {
            position: relative;
            display: inline-block;
            line-height: 1;
            color: $base-light !important;
            font-weight: 200;

            @media (max-width: $screen-tablet) and (orientation: landscape) {
                color: $primary-color !important;
            }
            @media (min-width: $screen-tablet) {
                color: $primary-color !important;
            }

            strong {
                font-weight: 700;
            }
            span {
                position: relative;
                z-index: 0;
                display: inline;
                line-height: 1.4;
                background-color: $main-color;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    display: inline-block;
                    background-color: transparent;
                    line-height: 1;
                }
                @media (min-width: $screen-tablet) {
                    display: inline-block;
                    background-color: transparent;
                    line-height: 1;
                }
            }
            &:after {
                //content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                display: block;
                width: 0;
                height: .75em;
                background-color: $base-dark;
                opacity: 0;
                transition: $transition-default;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    content: "";
                }
                @media (min-width: $screen-tablet) {
                    content: "";
                }
            }
            &:hover,
            &:focus {
                text-decoration: none;
                
                &:after {
                    width: 100%;
                    opacity: .75;
                    transition: $transition-default;
                }
            }
        }
    }
    &__image {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2em;
    }
    &__body {
        p {
            font-size: 1.1rem;
            font-weight: 400;
        }
    }
    &--ux {
        .page-back {
            color: $base-light;

            @media (max-width: $screen-tablet) and (orientation: landscape) {
                color: $main-color;
            }
            @media (min-width: $screen-tablet) {
                color: $main-color;
            }
        }
        .title {
            color: $primary-color;
        }
        .go-to-other {
            background-color: $main-color;
        }
        a {
            color: $main-color;

            &:after {
                background-color: $yellow-mother;// $primary-color;
            }
        }
        .page-content__list {
            span {
                background-color: $primary-color;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    display: inline-block;
                    background-color: transparent;
                }
                @media (min-width: $screen-tablet) {
                    display: inline-block;
                    background-color: transparent;
                }
            }
        }
    }
    &--ui {
        .page-back {
            color: $base-light;
            
            @media (max-width: $screen-tablet) and (orientation: landscape) {
                color: $primary-color;
            }
            @media (min-width: $screen-tablet) {
                color: $primary-color;
            }
        }
        .title {
            color: $main-color;
        }
        .go-to-other {
            background-color: $primary-color;
        }
        a {
            color: $primary-color;

            &:after {
                background-color: $yellow-mother;// $main-color;
            }
        }
        .page-content__list {
            span {
                background-color: $main-color;

                @media (max-width: $screen-tablet) and (orientation: landscape) {
                    display: inline-block;
                    background-color: transparent;
                }
                @media (min-width: $screen-tablet) {
                    display: inline-block;
                    background-color: transparent;
                }
            }
        }
    }
}

.box-caller {
    .title {
        line-height: .9;
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: uppercase;

        @media (max-width: $screen-tablet) and (orientation: landscape) {
            width: 45vw;
            font-size: 2.5em;
        }
        @media (min-width: $screen-tablet) {
            width: auto;
            font-size: 6.5em;
        }

        b {
            font-size: 2em;
            font-weight: 700;
        }
    }
    .go-to-other {
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        border-radius: 100%;
        text-indent: -100vw;
        background-color: $base-dark;
    }
}

.bg-img-invisible {
    background-size: cover;

    img {
        opacity: 0;
    }
}